import client from '../config/AxiosConfig';
import { ImageFormData } from '../config/ConstructFormData';

export const GetWayPois = async (id, currentPage, limit, filters) =>
  client.get(`/ways/${id}/pois/${currentPage}/${limit}?filters=${filters}`);

export const GetPoi = async id => client.get(`/pois/${id}`);

export const CreatePoi = async data => client.post('/pois', data);

export const UpdatePoi = async (id, data) => client.put(`/pois/${id}`, data);

export const DeletePoi = async (id, data) => client.delete(`/pois/${id}`);

export const AddPoiImage = async (poi, image) =>
  client.put(`/pois/${poi}/images`, ImageFormData(image));

export const RemovePoiImage = async (poi, image) =>
  client.delete(`/pois/${poi}/images/${image}`);
