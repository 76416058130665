import React from "react";
import {
  Footer,
  FooterTitle,
  StoreLink,
  Partner,
  StoresSection,
  PartnersSection
} from "../ShareStyles";
import AppleStore from "../../../assets/icons/apple_store.svg";
import GoogleStore from "../../../assets/icons/google_store.svg";
import Logo from "../../../assets/icons/logo.svg";
import Euro from "../../../assets/icons/euro_sub.jpg";

const REACT_APP_ANDROID = process.env.REACT_APP_ANDROID;
const REACT_APP_IOS = process.env.REACT_APP_IOS;

const openLink = link => window.open(link, "_blank");

const FooterComponent = () => {
  return (
    <Footer>
      <FooterTitle>Saint James Way app available on:</FooterTitle>
      <StoresSection>
        <StoreLink src={AppleStore} onClick={() => openLink(REACT_APP_IOS)} />
        <StoreLink
          src={GoogleStore}
          onClick={() => openLink(REACT_APP_ANDROID)}
        />
      </StoresSection>
      <PartnersSection>
        <Partner src={Logo} />
        <Partner src={Euro} />
      </PartnersSection>
    </Footer>
  );
};

export default FooterComponent;
