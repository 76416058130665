import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton
} from '../../styles/BasicStyles';
import { GetUsers, DeleteUser } from '../../infra/requests/UsersRequests';
import UserFilters from './components/UserFilters';

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    types: [
      {
        title: 'Admin',
        dataIndex: true
      },
      {
        title: 'User',
        dataIndex: false
      }
    ],
    columns: [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'E-mail',
        dataIndex: 'email'
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        render: value => (value ? 'Yes' : 'No')
      },
      {
        title: 'Stamps',
        dataIndex: 'credential',
        render: value => value?.length
      },
      {
        title: 'Actions',
        render: data => (
          <React.Fragment>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Do you want to delete this user? You will not be able to recover it."
                onConfirm={() => this.deleteItem(data)}
              >
                <Icon type="delete" />
                {' Delete'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleFilterChange = filters => {
    this.setState({ filters }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  deleteItem = async data => {
    try {
      await DeleteUser(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetUsers(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, types } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Users</PageTitle>
            Users list
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <UserFilters types={types} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="team"
            emptyText={'There are not users'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default UsersPage;
