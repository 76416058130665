import WaysPage from '../../pages/dashboard/WaysPage';
import ManageWayPage from '../../pages/ways/ManageWayPage';
import WayDashboardPage from '../../pages/ways/WayDashboardPage';

import CitiesPage from '../../pages/cities/CitiesPage';
import ManageCityPage from '../../pages/cities/ManageCityPage';
import ManagePoiPage from '../../pages/pois/ManagePoiPage';
import PoiCommentsPage from '../../pages/comments/PoiCommentsPage';

export default [
  {
    path: '/',
    component: WaysPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way',
    component: WayDashboardPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/edit',
    component: ManageWayPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/cities',
    component: CitiesPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/cities/:id',
    component: ManageCityPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/poi/add',
    component: ManagePoiPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/poi/:id',
    component: ManagePoiPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/poi/:id/comments',
    component: PoiCommentsPage,
    isAuthenticated: true
  }
];
