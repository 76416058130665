import client from '../config/AxiosConfig';
import { ImageFormData } from '../config/ConstructFormData';

export const GetCity = async id => client.get(`/cities/${id}`);

export const UpdateCity = async (id, data) => client.put(`/cities/${id}`, data);

export const AddCityImage = async (city, image) =>
  client.put(`/cities/${city}/images`, ImageFormData(image));

export const RemoveCityImage = async (city, image) =>
  client.delete(`/cities/${city}/images/${image}`);
