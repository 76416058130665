import React from 'react';
import {
  StampSquareContainer,
  SquareContainer,
  Stamp,
  DateTextAbsolute,
  TitleText,
  IconPinkContainer,
  IconPinkSquareImage,
  SquareStampBottom
} from './DefaultStampStyles';
import Pattern1 from '../../../../assets/images/pattern1.png';

const SQ2 = ({ tdate, text, image }) => (
  <StampSquareContainer isPink>
    <SquareContainer isPink>
      <IconPinkContainer>
        <IconPinkSquareImage src={Pattern1} />
      </IconPinkContainer>
      <div
        style={{
          display: 'inline-block',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '50%',
          marginTop: '5px'
        }}
      >
        <Stamp sizew={80} sizeh={80} isPink src={image} />
      </div>
      <DateTextAbsolute
        style={{
          right: -18,
          bottom: 67
        }}
      >
        {tdate}
      </DateTextAbsolute>
      <SquareStampBottom
        style={{
          flexDirection: 'row',
          width: '100%'
        }}
      >
        <div>
          <TitleText>{text}</TitleText>
        </div>
      </SquareStampBottom>
    </SquareContainer>
  </StampSquareContainer>
);

export default SQ2;
