import UsersPage from '../../pages/users/UsersPage';
import UserCredentialPage from '../../pages/users/UserCredentialPage';
import AdminsPage from '../../pages/admins/AdminsPage';
import ManageAdminPage from '../../pages/admins/ManageAdminPage';
import ApproveCommentsPage from '../../pages/comments/ApproveCommentsPage';
import CertificateRequestsPage from '../../pages/requests/CertificateRequestsPage';

export default [
  {
    path: '/admins',
    component: AdminsPage,
    isAuthenticated: true
  },
  {
    path: '/admins/add',
    component: ManageAdminPage,
    isAuthenticated: true
  },
  {
    path: '/admins/:id',
    component: ManageAdminPage,
    isAuthenticated: true
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true
  },
  {
    path: '/users/:id',
    component: UserCredentialPage,
    isAuthenticated: true
  },
  {
    path: '/comments',
    component: ApproveCommentsPage,
    isAuthenticated: true
  },
  {
    path: '/requests',
    component: CertificateRequestsPage,
    isAuthenticated: true
  }
];
