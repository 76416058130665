import React from 'react';
import { Row, Col } from 'antd';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geojson from './Geojson';
import { CalculateGeojsonZoom } from './MapFunctions';
import { PrimaryColour } from '../../styles/Colours';
import POI from '../../assets/images/poi.png';

const Icon = {
  anchor: { x: 9, y: 30 },
  size: { width: 19, height: 30 },
  scaledSize: { width: 19, height: 30 },
  url: POI,
  labelOrigin: { x: 9, y: 40 }
};

const PoisMap = ({ way, pois }) => {
  if (!way?.geojson) return 'No GEOJSON';
  const region = CalculateGeojsonZoom(way.geojson);
  return (
    <Row>
      <Col xs={24}>
        <GoogleMap
          id="pois_map"
          mapContainerStyle={{ width: '100%', height: '500px' }}
          zoom={region.zoom}
          options={{ minZoom: 3 }}
          center={{ lat: region.latitude, lng: region.longitude }}
        >
          <Geojson
            geojson={way.geojson}
            strokeColor={PrimaryColour}
            fillColor={PrimaryColour}
            strokeWidth={5}
          />
          {pois.map(poi => (
            <Marker
              key={poi._id}
              draggable={false}
              label={{ text: poi.name, color: PrimaryColour }}
              icon={Icon}
              position={{
                lat: parseFloat(poi.coordinates?.latitude),
                lng: parseFloat(poi.coordinates?.longitude)
              }}
            />
          ))}
        </GoogleMap>
      </Col>
    </Row>
  );
};

export default PoisMap;
