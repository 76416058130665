import client from '../config/AxiosConfig';

export const GetPoiComments = async (id, currentPage, limit) =>
  client.get(`pois/${id}/comments/${currentPage}/${limit}`);

export const GetCommentsToApprove = async (id, currentPage, limit) =>
  client.get(`comments/${currentPage}/${limit}`);

export const ApproveComment = async id => client.put(`comments/${id}/validate`);

export const DeleteComment = async id => client.delete(`comments/${id}`);
