import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Popconfirm, Icon } from 'antd';
import React from 'react';
import TextAreaInput from './TextAreaInput';
import {
  LanguagesComponent,
  InputLabelDiv,
  AddLangButton,
  DeleteLangIcon,
  FormCollapse,
  FormPanel
} from './InputStyles';

const { Option } = Select;

const getLangName = code => {
  if (code === 'en') return 'English';
  if (code === 'pt') return 'Portuguese';
  if (code === 'es') return 'Spanish';
  if (code === 'pl') return 'Polish';
  if (code === 'fr') return 'French';
  if (code === 'lt') return 'Lithuanian';
  if (code === 'nl') return 'Dutch';
  return '';
};

const getLabel = (label, code) => {
  if (code === 'en') return `${label} in English`;
  if (code === 'pt') return `${label} in Portuguese`;
  if (code === 'es') return `${label} in Spanish`;
  if (code === 'pl') return `${label} in Polish`;
  if (code === 'fr') return `${label} in French`;
  if (code === 'lt') return `${label} in Lithuanian`;
  if (code === 'nl') return `${label} in Dutch`;
  return '';
};

const LanguagesInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  meta,
  errors,
  error
}) => {
  const langs = Object.keys(input.value ? input.value : { en: '' });

  const showError = meta.invalid && meta.submitFailed;

  const applyError = key => {
    const exist = meta.error && Object.keys(meta.error).find(x => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    result[code] = event.target.value;
    input.onChange(result);
  };
  const addLang = key => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    result[key] = '';
    input.onChange(result);
  };
  const removeLang = key => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    delete result[key];
    input.onChange(result);
  };
  const deleteIcon = key => {
    if (key !== 'en') {
      return (
        <DeleteLangIcon onClick={e => e.stopPropagation()}>
          <Popconfirm
            placement="top"
            title="Are you sure you want to delete this language?"
            onConfirm={() => removeLang(key)}
          >
            <Icon type="delete" />
          </Popconfirm>
        </DeleteLangIcon>
      );
    }
    return null;
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      <FormCollapse accordion>
        {langs.map(key => (
          <FormPanel
            error={applyError(key)}
            header={getLabel(label, key)}
            key={key}
            extra={deleteIcon(key)}
          >
            <TextAreaInput
              meta={{
                invalid: applyError(key),
                submitFailed: meta.submitFailed
              }}
              label=""
              placeholder={placeholder}
              input={{
                value: input?.value?.[key],
                onChange: e => changeInput(e, key)
              }}
            />
          </FormPanel>
        ))}
      </FormCollapse>
      <AddLangButton>
        <Select
          placeholder="Add another language"
          onSelect={code => addLang(code)}
          value="Add another language"
        >
          {languages.map(lang => {
            if (!lang.active && !langs.find(x => lang.code === x))
              return (
                <Option key={lang.code} value={lang.code}>
                  {getLangName(lang.code)}
                </Option>
              );
            return null;
          })}
        </Select>
      </AddLangButton>
    </LanguagesComponent>
  );
};

LanguagesInput.propTypes = {
  label: PropTypes.string
};

export default withLocalize(LanguagesInput);
