import React from 'react';
import {
  StampRectangleContainer,
  RectangleStampContainer,
  Stamp,
  StampContent,
  TitleText,
  RectangleTextDateContainer,
  IconPinkSquareImage,
  DateTextNormal,
  RectangleTextBottom,
  StampRound
} from './DefaultStampStyles';
import Pattern2 from '../../../../assets/images/pattern2.png';

const RT1 = ({ tdate, text, image }) => (
  <StampRectangleContainer isPink>
    <RectangleStampContainer>
      <StampRound>
        <Stamp isPink sizew="70" sizeh="70" src={image} />
      </StampRound>
      <StampContent>
        <RectangleTextDateContainer style={{ right: '10px' }}>
          <DateTextNormal isLeft>{tdate}</DateTextNormal>
        </RectangleTextDateContainer>
        <IconPinkSquareImage src={Pattern2} />
        <RectangleTextBottom>
          <TitleText isPink>{text}</TitleText>
        </RectangleTextBottom>
      </StampContent>
    </RectangleStampContainer>
  </StampRectangleContainer>
);

export default RT1;
