import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './types/AuthenticatedRoute';
import UnauthenticatedRoute from './types/UnauthenticatedRoute';
import NotFound from '../pages/not_found/NotFound';
import CombinedRoutes from './CombinedRoutes';

const ApplicationRoutes = ({ childProps }) => {
  return (
    <Switch>
      {CombinedRoutes.map((route, key) =>
        route.isAuthenticated ? (
          <AuthenticatedRoute
            key={key}
            path={route.path}
            exact
            component={route.component}
            props={childProps}
          />
        ) : (
          <UnauthenticatedRoute
            path={route.path}
            key={key}
            exact
            component={route.component}
            props={childProps}
          />
        )
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default ApplicationRoutes;