import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import {
  GetTemplate,
  UpdateTemplate
} from '../../infra/requests/EmailTemplatesRequests';
import {
  FormContainer,
  SpinLoading,
  Separator,
  BaseForm,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  InputContainer,
  HtmlPreview,
  ExpandWindowContainer,
  NoteContainer
} from './Styles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/inputs/TextInput';
import { connect } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import BaseButton from '../../components/buttons/BaseButton';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';

const baseTemplateID = '6194d2bb3e1dd25358a8b4d5';

const FormValidations = values => {
  let errors = {};

	if(values._id === baseTemplateID) {
		errors = FormValidator.make({
			html: 'required'
		})(values);
	}
  else {
    errors = FormValidator.make({
      subject: 'required',
			html: 'required'
		})(values);
  }

  return errors;
};

class ManageEmailTemplatesPage extends Component {
  state = { 
    loading: true,
    info: undefined,
    viewSize: 1
  };

  componentDidMount = async () => {
    const {
      match: { params },
      history,
      dispatch
    } = this.props;

    //The "Base Template" we edit in a different area
    if(params.id === baseTemplateID) {
      return history.push('/email-templates');
    }

    let templateID = params.id;
    if(params.id === 'base-template') templateID = baseTemplateID;

    const result = await GetTemplate(templateID);

    dispatch(
      initialize('manage_email_templates_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      info: result.data
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;
      const { info } = this.state;

      if(!info?.html) {
        notification.error({
          message: 'Error',
          description: 'You must insert some content in the email.'
        });
        return this.setState({ loading: false });
      }
      
      let templateID = params.id;
      const payload = FlattenToFormData({ ...values, html: info?.html });
      if(params.id === 'base-template') {
        if(!info?.html.includes('{{TEMPLATE}}')) {
          notification.error({
            message: 'Error',
            description: "You can't remove the tag '{{TEMPLATE}}' in this template, otherwise the emails will be sent without content."
          });
          return this.setState({ loading: false });
        }

        templateID = baseTemplateID;
      }

      const { success } = await UpdateTemplate(templateID, payload);

      if (success) return history.push('/email-templates');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/email-templates');
  };

  onChange = (value) => {
    const { info } = this.state;
    info.html = value;

    this.setState({
      info
    });
  }

  changeView = () => {
    const { viewSize } = this.state;
    const size = viewSize === 1 ? 2 : 1;

    this.setState({
      viewSize: size
    });
  }

  render() {
    const { match: { params }, handleSubmit } = this.props;
    const { loading, info, viewSize } = this.state;

    if(loading) return <SpinLoading />;

    let extraTitle = '';
    if(params.id === 'base-template') extraTitle = 'Edit Header & Footer';
    else extraTitle = info?.name;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Email Templates</PageTitle>
            {`Email Templates > ${extraTitle} > Edit`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text='Save'
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text='Cancel'
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24} style={{ marginBottom: '15px' }}>
              <Col xs={24} md={12} offset={0}>
                <TextInput
                  type='text'
                  label='Name'
                  disabled={true}
                  meta={{}}
                  input={{
                    value: info?.name,
                    onChange: () => null
                  }}
                />
              </Col>
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} offset={0}>
                <NoteContainer>
                  <span>Note: </span>You must pay attention to the TAGS available in the template. You cannot modify or add new ones, but you can use or not the available TAGS as you wish.
                </NoteContainer>
              </Col>
            </Row>
            {
              params.id !== 'base-template' &&
              <React.Fragment>
                <Row gutter={24}>
                  <Col xs={24} md={12} offset={0}>
                    <Field
                      component={CheckboxInput}
                      name={'includeHeaderFooter'}
                      label='Include Header & Footer in this template?'
                      importantLabel={true}
                      inlineCheckbox={true}
                    />
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '40px' }}>
                  <Col xs={24} md={12}>
                    <Field
                      component={TextInput}
                      name={'subject'}
                      type='text'
                      label={`Subject *`}
                      placeholder='Subject'
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }
            <ExpandWindowContainer>
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col xs={24} offset={0}>
                  <BaseButton
                    type='primary'
                    icon='fullscreen'
                    text={viewSize === 1 ? 'Expand Window' : 'Shrink Window'}
                    onClick={() => this.changeView()}
                  />
                </Col>
              </Row>
            </ExpandWindowContainer>
            <Row gutter={24}>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <InputContainer hasMargin={ viewSize === 1 ? false : true }>
                  <AceEditor
                    placeholder='Insert the email content'
                    mode='html'
                    theme='monokai'
                    name='html'
                    onLoad={editorInstance => {
                      editorInstance.container.style.resize = 'both';
                      document.addEventListener('mouseup', e => (
                        editorInstance.resize()
                      ));
                    }}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={info?.html}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </InputContainer>
              </Col>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <HtmlPreview
                  dangerouslySetInnerHTML={{
                  __html: info?.html
                  }}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageEmailTemplatesPage = reduxForm({
  form: 'manage_email_templates_form',
  validate: FormValidations
})(ManageEmailTemplatesPage);


export default connect()(ManageEmailTemplatesPage);