import React, { Fragment } from 'react';
import SQ1 from './SQ1';
import SQ2 from './SQ2';
import RT1 from './RT1';
import RT2 from './RT2';
import RT3 from './RT3';
import moment from 'moment';
import CultureIcon from '../../../../assets/images/stamp_culture.png';
import AccommodationIcon from '../../../../assets/images/stamp_accommodation.png';
import SupportIcon from '../../../../assets/images/stamp_support.png';
import OthersIcon from '../../../../assets/images/stamp_others.png';

const getStampByCategory = category => {
  switch (category) {
    case '5d923f3ec1c39bd18663dfe9':
      return CultureIcon;
    case '5d923f4ac1c39bd18663dfea':
      return AccommodationIcon;
    case '5d923f53c1c39bd18663dfeb':
      return SupportIcon;
    case '5d923f58c1c39bd18663dfec':
      return OthersIcon;
    default:
      return CultureIcon;
  }
};

const StampsComponent = ({ stamp }) => {
  return (
    <Fragment>
      {stamp.type === 'sq1' && (
        <SQ1
          tdate={moment(stamp.createdAt).format('DD | MM | YYYY')}
          text={stamp.name}
          image={getStampByCategory(stamp.category)}
        />
      )}
      {stamp.type === 'sq2' && (
        <SQ2
          tdate={moment(stamp.createdAt).format('DD | MM | YYYY')}
          text={stamp.name}
          image={getStampByCategory(stamp.category)}
        />
      )}
      {stamp.type === 'rt1' && (
        <RT1
          tdate={moment(stamp.createdAt).format('DD | MM | YYYY')}
          text={stamp.name}
          image={getStampByCategory(stamp.category)}
        />
      )}
      {stamp.type === 'rt2' && (
        <RT2
          tdate={moment(stamp.createdAt).format('DD | MM | YYYY')}
          text={stamp.name}
          image={getStampByCategory(stamp.category)}
        />
      )}
      {stamp.type === 'rt3' && (
        <RT3
          tdate={moment(stamp.createdAt).format('DD | MM | YYYY')}
          text={stamp.name}
          image={getStampByCategory(stamp.category)}
        />
      )}
    </Fragment>
  );
};

export default StampsComponent;
