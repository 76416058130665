import PropTypes from "prop-types";
import React from "react";
import { InputDiv, InputLabelDiv, InputField } from "./InputStyles";

const PrefixNumber = ({
  input,
  meta,
  size,
  label,
  first,
  max,
  min,
  step,
  placeholder,
  disabled
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <InputField
        disabled={disabled}
        placeholder={placeholder}
        value={input.value}
        onChange={input.onChange}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

PrefixNumber.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default PrefixNumber;
