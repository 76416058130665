import React from 'react';
import { Row, Col } from 'antd';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { CalculateStampsZoom } from './MapFunctions';
import { PrimaryColour } from '../../styles/Colours';
import POI from '../../assets/images/poi.png';
import UserPin from '../../assets/images/user_pin.png';
import moment from 'moment';

const Icon = {
  anchor: { x: 9, y: 30 },
  size: { width: 19, height: 30 },
  scaledSize: { width: 19, height: 30 },
  url: POI,
  labelOrigin: { x: 9, y: 40 }
};

const UserIcon = {
  anchor: { x: 9, y: 30 },
  size: { width: 19, height: 30 },
  scaledSize: { width: 19, height: 30 },
  url: UserPin,
  labelOrigin: { x: 9, y: 40 }
};

const UserStampsMap = ({ stamps }) => {
  const region = CalculateStampsZoom(stamps);
  return (
    <Row>
      <Col xs={24}>
        <GoogleMap
          id="user_stamps"
          mapContainerStyle={{ width: '100%', height: '500px' }}
          zoom={region.zoom}
          options={{ minZoom: 3 }}
          center={{ lat: region.latitude, lng: region.longitude }}
        >
          {stamps.map(stamp => (
            <React.Fragment key={stamp._id}>
              <Marker
                draggable={false}
                label={{ text: stamp?.poi?.name, color: PrimaryColour }}
                icon={Icon}
                position={{
                  lat: parseFloat(stamp?.poi?.coordinates?.latitude),
                  lng: parseFloat(stamp?.poi?.coordinates?.longitude)
                }}
              />
              <Marker
                draggable={false}
                icon={UserIcon}
                zIndex={99}
                label={{
                  text: moment(stamp.createdAt).format('HH:mm, DD/MM/YYYY'),
                  color: 'black'
                }}
                position={{
                  lat: parseFloat(stamp?.latitude),
                  lng: parseFloat(stamp?.longitude)
                }}
              />
            </React.Fragment>
          ))}
        </GoogleMap>
      </Col>
    </Row>
  );
};

export default UserStampsMap;
