import PropTypes from "prop-types";
import React from "react";
import { InputDiv, InputLabelDiv, InputField } from "./InputStyles";

const WebsiteInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  type,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const verifyOnBlur = () => {
    if (input.value && input.value.indexOf("http") !== 0)
      input.onChange("https://" + input.value);
  };

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <InputField
        disabled={disabled}
        prefix={prefix}
        size={size}
        suffix={suffix}
        type={type}
        value={input.value}
        onChange={input.onChange}
        onBlur={verifyOnBlur}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

WebsiteInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func
};

export default WebsiteInput;
