import styled from 'styled-components';
import { Menu, Layout } from 'antd';
import { NavLink } from 'react-router-dom';

const { Sider, Header, Content } = Layout;

export const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 999;
  background-color: ${p => p.theme.primaryColor};

  .ant-layout-sider-trigger {
    background-color: ${p => p.theme.primaryColor};
  }
`;

export const StyledLogo = styled.img`
  width: calc(100% - 10px);
  height: calc(64px - 10px);
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

export const StyledHeader = styled(Header)`
  height: 64px;
  width: 100%;
  background-color: ${p => p.theme.primaryColor};
  position: fixed;
  z-index: 999;
  padding: 0px 0px 0px 20px;
`;

export const StyledMenu = styled(Menu)`
  background-color: #fff;
  width: 100%;
  height: calc(100% - 16px);
`;

export const StyledMenuDivider = styled(Menu.Divider)`
  background-color: #000;
  width: 100%;
  height: 5px;
  list-style-type: none;
`;

export const StyledMenuItem = styled(Menu.Item)`
  color: black;
  a {
    color: black;
  }
  &:hover {
    color: ${p => p.theme.primaryColor};
  }
`;

export const MenuSVG = styled.i`
  width: ${({ size }) => size || '15px'};
  height: ${({ size }) => size || '15px'};
  display: inline-block;
  margin-right: 10px;

  svg {
    width: ${({ size }) => size || '15px'};
    height: ${({ size }) => size || '15px'};
  }
`;

export const StyledNavLink = styled(NavLink)`
  &.activated {
    color: ${p => p.theme.primaryColor};
    ${MenuSVG} {
      color: ${p => p.theme.primaryColor};
      fill: ${p => p.theme.primaryColor};
    }
  }

  &:hover {
    ${MenuSVG} {
      color: ${p => p.theme.primaryColor};
      fill: ${p => p.theme.primaryColor};
    }
  }
`;

export const MenuIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const StyledContent = styled(Content)`
  width: 100%;
  min-height: 100vh;
  padding-top: 64px;
  display: inline-block;
  background-color: ${p => p.theme.primaryBackgroundColor};
  text-align: center;
`;

export const HeaderTitle = styled.h1`
  height: 64px;
  line-height: 64px;
  font-size: 21px;
  font-weight: 350;
  text-transform: uppercase;
  color: white;
  margin-bottom: 0px;
`;

export const TransitionLayout = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: ${({ collapsed }) => (collapsed ? '80px' : '200px')};
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: padding-left;
  transition-timing-function: ease;
`;
