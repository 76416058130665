import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetDynamicPages } from '../../infra/requests/BaseRequests';

const renderName = tag => {
  if (tag === 'terms_and_conditions') return 'Terms and Conditions';
  if (tag === 'contacts') return 'Contacts';
  if (tag === 'privacy_statement') return 'Privacy Statement';
  if (tag === 'about_project') return 'About the project';
  if (tag === 'about_app') return 'About the app';
  if (tag === 'faqs') return 'FAQs';
  return tag;
};

class DynamicPages extends Component {
  state = {
    loading: false,
    columns: [
      {
        title: 'Pages',
        dataIndex: 'tag',
        render: renderName
      }
    ],
    rows: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/dynamic-pages/${record.tag}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetDynamicPages();
      this.setState({
        rows: result.data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Dynamic Pages</PageTitle>
            Dynamic Pages in the mobile application
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon="copy"
            emptyText={'No Pages'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default DynamicPages;
