import styled from "styled-components";
import { Card } from "antd";

export const ShareContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
`;

export const ShareCard = styled(Card)`
  &&& {
    margin: 100px 0;
    background-color: white;
    width: 420px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.thirdColor};
  }
`;

export const UserSection = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
  height: 18px;
`;

export const SectionLabel = styled.div`
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
`;

export const SectionValue = styled.div`
  display: inline-block;
  font-size: 16px;
`;

export const CenterPhoto = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
`;

export const PhotoSection = styled.div`
  display: inline-block;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;
`;

export const InfoSection = styled.div`
  margin: 0 -24px;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  width: calc(100% + 48px);
  padding: 15px 30px;
`;

export const EmptyStamp = styled.div`
  margin: 10px;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  width: calc(50% - 20px);
  float: left;
  padding-bottom: ${({ half }) =>
    half ? "calc(25% - 20px)" : "calc(50% - 20px)"};
`;

export const NotFound = styled.div`
  font-size: 30px;
`;

export const LogoSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

export const LogoImg = styled.img`
  max-width: 100%;
  width: 150px;
`;

export const UserCredentialSection = styled.div`
  display: inline-block;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 60px;
  padding-bottom: 100px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 850px) {
    padding: 30px;
    padding-bottom: 130px;
  }

  @media (max-width: 600px) {
    padding: 15px;
    padding-bottom: 130px;
  }
`;

export const Title = styled.h1`
  font-size: ${({ subtitle }) => (subtitle ? "32px" : "30px")};
  font-weight: bold;
  color: #3e3e3e;
  margin-top: ${({ subtitle }) => (subtitle ? "10px" : 0)};

  @media (max-width: 600px) {
    font-size: ${({ subtitle }) => (subtitle ? "22px" : "20px")};
    margin-top: ${({ subtitle }) => (subtitle ? "10px" : 0)};
  }
`;

export const DatesLabel = styled.div`
  margin-top: 22px;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;

  @media (max-width: 600px) {
    margin-top: 15px;
    font-size: 16px;
  }
`;
export const DatesValue = styled.div`
  margin-top: 5px;
  font-size: 26px;
  font-weight: 400;
  color: #3e3e3e;

  @media (max-width: 600px) {
    margin-top: 0;
    font-size: 16px;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 60px;
  background: #f7f3f3;
  z-index: 999;

  @media (max-width: 850px) {
    padding: 20px 30px;
  }

  @media (max-width: 600px) {
    padding: 10px 15px;
  }
`;

export const FooterTitle = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 400;
  color: #3e3e3e;
  margin-top: 4px;

  @media (max-width: 850px) {
    font-size: 14px;
    margin-top: 0;
  }
`;

export const StoresSection = styled.div`
  float: left;

  @media (max-width: 850px) {
    clear: left;
    margin-top: 10px;
  }
`;
export const PartnersSection = styled.div`
  float: right;

  @media (max-width: 850px) {
    margin-top: 10px;
  }
`;

export const StoreLink = styled.img`
  display: inline-block;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;

  @media (max-width: 850px) {
    height: 30px;
    margin-left: 0;
    margin-right: 10px;
  }
`;
export const Partner = styled.img`
  display: inline-block;
  height: 40px;
  margin-left: 20px;

  @media (max-width: 850px) {
    height: 35px;
    margin-left: 10px;
  }
`;

export const CredentialIcon = styled.img`
  position: absolute;
  top: 60px;
  width: 180px;
  right: 0;

  @media (max-width: 850px) {
    width: 160px;
    top: 30px;
  }

  @media (max-width: 600px) {
    width: 120px;
    top: 15px;
  }
`;
