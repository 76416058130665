import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import NumberInput from './NumberInput';
import { InputDiv, InputLabelDiv, MapSearchInput } from './InputStyles';

class MapInput extends Component {
  state = {
    zoom: 10,
    latitude: parseFloat(this.props?.input?.value?.latitude) || 47,
    longitude: parseFloat(this.props?.input?.value?.longitude) || 10
  };
  autocomplete = undefined;

  handleDrag = event => {
    const { input } = this.props;
    const result = {
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng()
    };
    this.setState({ ...result });
    input.onChange(result);
  };

  handleInputChange = (key, value) => {
    if (value && parseFloat(value)) {
      const { input } = this.props;
      this.setState({ [key]: parseFloat(value) }, () => {
        input.onChange({
          latitude: this.state.latitude,
          longitude: this.state.longitude
        });
      });
    }
  };

  onLoad = autocomplete => {
    this.autocomplete = autocomplete;
  };

  onPlaceChanged = () => {
    if (this.autocomplete !== undefined) {
      const local = this.autocomplete.getPlace();
      if (local?.geometry?.location) {
        const { input } = this.props;
        const result = {
          latitude: local?.geometry?.location.lat(),
          longitude: local?.geometry?.location.lng()
        };
        this.setState({ ...result, zoom: 18 });
        input.onChange(result);
      }
    }
  };

  render() {
    const { meta, label } = this.props;
    const { latitude, longitude, zoom } = this.state;
    return (
      <InputDiv>
        <InputLabelDiv>{label}</InputLabelDiv>

        <GoogleMap
          id="direction-example"
          mapContainerStyle={{ width: '100%', height: '400px' }}
          zoom={zoom}
          options={{ minZoom: 3 }}
          center={{ lat: latitude, lng: longitude }}
        >
          <Autocomplete
            onLoad={this.onLoad}
            onPlaceChanged={this.onPlaceChanged}
          >
            <MapSearchInput type="text" placeholder="Search for a place" />
          </Autocomplete>
          <Marker
            draggable={true}
            onDragEnd={this.handleDrag}
            position={{
              lat: latitude,
              lng: longitude
            }}
          />
        </GoogleMap>
        <Row gutter={24}>
          <Col xs={12}>
            <NumberInput
              label="Latitude"
              input={{
                value: latitude,
                onChange: value => this.handleInputChange('latitude', value)
              }}
              meta={meta}
            />
          </Col>
          <Col xs={12}>
            <NumberInput
              label="Longitude"
              input={{
                value: longitude,
                onChange: value => this.handleInputChange('longitude', value)
              }}
              meta={meta}
            />
          </Col>
        </Row>
      </InputDiv>
    );
  }
}

MapInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default MapInput;
