import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import {
  GetDynamicPage,
  UpdateDynamicPage
} from '../../infra/requests/BaseRequests';
import TextInput from '../../components/inputs/TextInput';
import DraftInput from '../../components/inputs/DraftInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderTitle,
  HeaderContainer,
  PageTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  tag: 'required',
  content: 'required'
});

const renderName = tag => {
  if (tag === 'terms_and_conditions') return 'Terms and Conditions';
  if (tag === 'contacts') return 'Contacts';
  if (tag === 'privacy_statement') return 'Privacy Statement';
  if (tag === 'about_project') return 'About the project';
  if (tag === 'about_app') return 'About the app';
  if (tag === 'faqs') return 'FAQs';
  return tag;
};

class ManageLessonsPage extends Component {
  state = {
    loading: true,
    title: ''
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if (params.tag) {
      const result = await GetDynamicPage(params.tag);
      dispatch(initialize('manage_dynamic_page', result.data));
      this.setState({ loading: false, title: renderName(params.tag) });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const result = await UpdateDynamicPage(params.tag, values);
      if (result && result.success) return history.push('/dynamic-pages');
      else this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/dynamic-pages');
  };

  render() {
    const { handleSubmit } = this.props;
    const { loading, title } = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
            {`Dynamic Pages > ${title}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Save"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancel"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="tag"
              type="text"
              label="Tag"
              placeholder="Tag"
              disabled
            />
            <Field
              component={DraftInput}
              name="content"
              type="text"
              label="Page Content"
              placeholder="Define page content"
              fixedPosition={400}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

export default reduxForm({
  form: 'manage_dynamic_page',
  validate: validations
})(ManageLessonsPage);
