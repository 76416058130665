import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  initialize,
  FormSection,
  FieldArray
} from "redux-form";
import { Row, Col } from "antd";
import { withLocalize } from "react-localize-redux";
import {
  GetPoi,
  CreatePoi,
  UpdatePoi,
  AddPoiImage,
  RemovePoiImage
} from "../../infra/requests/PoiRequests";
import { CreateImage, DeleteImage } from "../../infra/requests/ImageRequests";
import { CitiesDropdown } from "../../infra/requests/BaseRequests";
import TextInput from "../../components/inputs/TextInput";
import WebsiteInput from "../../components/inputs/WebsiteInput";
import SelectInput from "../../components/inputs/SelectInput";
import LanguagesInput from "../../components/inputs/LanguagesInput";
import MapInput from "../../components/inputs/MapInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import ImageSlideInput from "../../components/inputs/ImageSlideInput";
import NumberInput from "../../components/inputs/NumberInput";
import PrefixNumber from "../../components/inputs/PrefixNumber";
import BaseButton from "../../components/buttons/BaseButton";
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";

const validations = FormValidator.make({
  name: "required",
  city: "required",
  category: "required",
  description: "required|emptyLanguage",
  coordinates: "required"
});

class ManagePoiPage extends Component {
  state = {
    isNew: false,
    loading: true,
    companies: [],
    unsaved: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const { data } = await CitiesDropdown(params.way);
    if (params.id) {
      const result = await GetPoi(params.id);
      dispatch(
        initialize("manage_poi_form", {
          ...result.data,
          city: result.data?.city?._id,
          category: result.data?.category?._id
        })
      );
      this.setState({ loading: false, cities: data });
    } else {
      this.setState({
        isNew: true,
        loading: false,
        cities: data
      });
    }

    window.addEventListener("beforeunload", () => {
      const { unsaved } = this.state;
      if (unsaved.length) {
        unsaved.forEach(image => DeleteImage(image._id));
      }
    });
  };

  componentWillUnmount = () => {
    const { unsaved } = this.state;
    if (unsaved.length) {
      unsaved.forEach(image => DeleteImage(image._id));
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const { isNew } = this.state;
      const { success } = isNew
        ? await CreatePoi(values)
        : await UpdatePoi(params.id, values);
      if (success) {
        this.setState({ loading: false, unsaved: [] }, () =>
          history.push(`/ways/${params.way}`)
        );
      } else this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleDeleteImage = async id => {
    const {
      match: { params }
    } = this.props;
    if (params.id) {
      return await RemovePoiImage(params.id, id);
    } else {
      await this.setState({
        unsaved: this.state.unsaved.filter(img => img._id !== id)
      });
      return await DeleteImage(id);
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;
    return history.push(`/ways/${params.way}`);
  };

  handleAddImage = async image => {
    const {
      match: { params }
    } = this.props;
    const { isNew } = this.state;

    if (isNew) {
      const { data, success } = await CreateImage(image);
      if (success) {
        const { unsaved } = this.state;
        this.setState({ unsaved: [...unsaved, data] });
        return data;
      }
      return undefined;
    } else {
      const { data, success } = await AddPoiImage(params.id, image);
      if (success) return data;
      return undefined;
    }
  };

  render() {
    const { handleSubmit, categories } = this.props;
    const { isNew, loading, cities } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? "Add Point of Interest" : "Edit Point of Interest";
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Save"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancel"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <SectionTitle>Basic Info</SectionTitle>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Name"
              placeholder="Name the Point of Interest"
            />
            <Field
              component={SelectInput}
              name="city"
              type="text"
              label="City"
              placeholder="Select a city"
              data={cities}
              dataKey={"_id"}
              dataLabel={"name"}
            />
            <Field
              component={SelectInput}
              name="category"
              type="text"
              label="Category"
              placeholder="Select a category"
              data={categories}
              dataKey={"_id"}
              dataLabel={"name"}
            />
            <Field
              component={LanguagesInput}
              name="description"
              label="About the Point of Interest"
              placeholder="Description of the Point of interest"
            />
            <Field
              component={TextAreaInput}
              name="schedule"
              type="text"
              label="Schedule"
              placeholder="Schedule of Point of interest"
            />
            <Field
              component={TextInput}
              name="address"
              type="text"
              label="Address"
              placeholder="Address"
            />
            <FormSection name="contact">
              <Row gutter={12}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name="name"
                    type="text"
                    label="Contact Name"
                    placeholder="Contact Name"
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    component={PrefixNumber}
                    name="prefix"
                    label="Prefix"
                    placeholder="Prefix"
                  />
                </Col>
                <Col xs={18}>
                  <Field
                    component={NumberInput}
                    name="number"
                    type="number"
                    step={1}
                    label="Number"
                    placeholder="Number"
                  />
                </Col>
              </Row>
            </FormSection>
            <Field
              component={TextInput}
              name="email"
              type="text"
              label="Email"
              placeholder="Email"
            />
            <Field
              component={WebsiteInput}
              name="website"
              type="text"
              label="Website"
              placeholder="Website"
            />
            <SectionTitle subsection>Gallery</SectionTitle>
            <FieldArray
              component={ImageSlideInput}
              name="images"
              label="Images"
              handleAdd={this.handleAddImage}
              handleDelete={this.handleDeleteImage}
            />
            <SectionTitle subsection>Position</SectionTitle>
            <Field
              component={MapInput}
              name="coordinates"
              label="Coordinates"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePoiPage = reduxForm({
  form: "manage_poi_form",
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManagePoiPage);

const mapStateToProps = state => ({
  user: state.user,
  categories: state.info.categories
});

export default withLocalize(connect(mapStateToProps)(ManagePoiPage));
