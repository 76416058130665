export default [
  {
    to: '/',
    icon: 'read',
    name: 'Ways',
    exact: true
  },
  {
    to: '/admins',
    icon: 'lock',
    name: 'Admins',
    exact: true
  },
  {
    to: '/users',
    icon: 'team',
    name: 'Users',
    exact: true
  },
  {
    to: '/dynamic-pages',
    icon: 'copy',
    name: 'Dynamic Pages',
    exact: true
  },
  {
    to: '/comments',
    icon: 'message',
    name: 'Approve Comments',
    exact: true
  },
  {
    to: '/requests',
    icon: 'audit',
    name: 'Certificate Requests',
    exact: true
  },
  {
    type: 'menu',
    name: 'Email Templates',
    to: '/email-templates',
    icon: 'mail',
    exact: true
  }
];
