import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { GetWay, UpdateWay } from '../../infra/requests/WayRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NumberInput from '../../components/inputs/NumberInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const Difficulty = [
  { _id: 'easy', name: 'Easy' },
  { _id: 'medium', name: 'Medium' },
  { _id: 'hard', name: 'Hard' }
];

const validations = FormValidator.make({
  distance: 'required',
  name: 'required',
  altitude: 'required',
  description: 'required|emptyLanguage'
});

class ManageWayPage extends Component {
  state = {
    loading: true,
    companies: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const result = await GetWay(params.way);
    dispatch(initialize('manage_way_form', result.data));
    this.setState({ loading: false });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { success } = await UpdateWay(params.way, values);
      if (success) return history.push(`/ways/${params.way}`);
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;
    return history.push(`/ways/${params.way}`);
  };

  render() {
    const { handleSubmit } = this.props;
    const { loading } = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Edit Way Information</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Save"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancel"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Name"
              placeholder="Name the way"
            />
            <Field
              component={TextInput}
              name="country"
              label="Country"
              type="text"
              placeholder="Country of the Way"
            />
            <Field
              component={LanguagesInput}
              name="description"
              label="About the Way"
              placeholder="Description of the way"
            />
            <Field
              component={NumberInput}
              name="distance"
              label="Total distance"
              placeholder="Total kilometers of the way"
            />
            <Field
              component={SelectInput}
              data={Difficulty}
              name="difficulty"
              label="Difficulty"
              placeholder="Average difficulty of the way"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageWayPage = reduxForm({
  form: 'manage_way_form',
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageWayPage);

const mapStateToProps = state => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageWayPage));
