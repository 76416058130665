import React from 'react';
import {
  StampSquareContainer,
  SquareContainer,
  Stamp,
  DateTextAbsolute,
  TitleText,
  SquareTextContainer,
  SquareStampContainer
} from './DefaultStampStyles';

const SQ1 = ({ tdate, text, image }) => (
  <StampSquareContainer>
    <SquareContainer>
      <DateTextAbsolute
        style={{
          left: '-130px',
          bottom: '220px'
        }}
      >
        {tdate}
      </DateTextAbsolute>
      <SquareStampContainer>
        <Stamp sizew={80} sizeh={80} src={image} />
      </SquareStampContainer>
      <SquareTextContainer>
        <TitleText>{text}</TitleText>
      </SquareTextContainer>
    </SquareContainer>
  </StampSquareContainer>
);

export default SQ1;
