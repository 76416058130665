import { Redirect, Route } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import * as loadash from 'lodash';

const AuthenticatedRoute = ({
  user,
  component: Component,
  props: cProps,
  ready,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout {...props} {...cProps}>
          {!ready ? (
            <Spin />
          ) : !loadash.isEmpty(user) ? (
            <Component {...props} {...cProps} />
          ) : (
            <Redirect to={'/login'} />
          )}
        </Layout>
      )}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  ready: state.info.ready
});

export default connect(mapStateToProps)(AuthenticatedRoute);
