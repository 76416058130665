import React from 'react';
import moment from 'moment';
import {
  UserSection,
  SectionLabel,
  SectionValue,
  PhotoSection
} from '../UserStyles';
import ImageComponent from '../../../components/images/ImageComponent';
import Placeholder from '../../../assets/images/placeholder.jpg';

const UserCredentialPage = ({ user }) => {
  return (
    <React.Fragment>
      <UserSection>
        <PhotoSection>
          <ImageComponent
            url={user?.photo?.url || Placeholder}
            color={user?.photo?.color}
            width="100%"
            ratio={1}
          />
        </PhotoSection>
      </UserSection>
      <UserSection>
        <SectionLabel>Name:</SectionLabel>
        <SectionValue>{user.name || 'Rather not say'}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Email:</SectionLabel>
        <SectionValue>{user.email || 'Rather not say'}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Birthday:</SectionLabel>
        <SectionValue>
          {user.birth
            ? moment(user.birth).format('DD/MM/YYYY')
            : 'Rather not say'}
        </SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Gender:</SectionLabel>
        <SectionValue>{user.gender || 'Rather not say'}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Nationality:</SectionLabel>
        <SectionValue>{user.nationality || 'Rather not say'}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Motivation:</SectionLabel>
        <SectionValue>{user.motivation || 'Rather not say'}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>Created:</SectionLabel>
        <SectionValue>
          {moment(user.createdAt).format('DD/MM/YYYY')}
        </SectionValue>
      </UserSection>
    </React.Fragment>
  );
};

export default UserCredentialPage;
