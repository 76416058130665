import styled from 'styled-components';
const alabaster = '#F9F9F9';
const fairPink = '#FFECED';
const gallery = '#EEEDED';
const darkGrey = '#3E3E3E';
const pippin = '#FFDDDD';
const sunsetOrange = '#FA4753';

export const StampSquareContainer = styled.div`
  width: 180px;
  height: 180px;
  display: inline-block;
  background-color: ${({ isPink }) => (isPink ? fairPink : alabaster)};
  border-radius: 4px;
`;

export const StampRectangleContainer = styled.div`
  width: 180px;
  height: 85px;
  background-color: ${({ isPink }) => (isPink ? fairPink : alabaster)};
  border-radius: 4px;
  display: inline-block;
`;

export const SquareContainer = styled.div`
  margin: 12px;
  display: inline-block;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  flex-direction: column;
  border: 1px;
  border-style: dotted;
  flex: 1;
  border-color: ${darkGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 7px;
  position: relative;
`;

export const SquareTextContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 10px;
`;

export const SquareStampContainer = styled.div`
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border-radius: 50%;
  overflow: hidden;
`;

export const SquareStampTop = styled.div`
  height: 85px;
  flex-direction: row;
`;

export const SquareStampBottom = styled.div`
  flex-direction: column;
  position: absolute;
  bottom: 7px;
  left: 7px;
`;

export const RectangleTextBottom = styled.div`
  margin-top: ${({ Normal }) => (Normal ? 6 : 2)}px;
  margin-bottom: ${({ Normal }) => (Normal ? 4 : 0)}px;
`;

export const RectangleStampContainer = styled.div`
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  margin: 6px;
`;

export const Ver = styled.div`
  margin-right: 6px;
`;

export const RectangleTextContainer = styled.div`
  width: calc(100% - 80px);
  margin-left: 10px;
  float: right;
  height: 68px;
`;

export const RectangleTextDateContainer = styled.div`
  margin-top: ${({ spacet }) => (spacet ? spacet : 2)}px;
  position: absolute;
  width: 100%;
`;

export const RectangleImage = styled.div`
  padding-top: ${({ Normal }) => (Normal ? 4 : 8)}px;
`;

export const SquareStampDate = styled.div`
  flex: 1;
  position: relative;
`;

export const SquareStampPinkDate = styled.div`
  justify-content: flex-end;
`;

export const IconPinkContainer = styled.div`
  align-items: center;
  margin-bottom: 5;
`;

export const IconPinkSquareImage = styled.img`
  width: 100%;
  min-height: 15px;
  margin-bottom: 3px;
`;

export const Stamp = styled.img`
  width: ${({ sizew }) => (sizew ? sizew : 62)}px;
  height: ${({ sizeh }) => (sizeh ? sizeh : 62)}px;
  background-color: ${({ isPink }) => (isPink ? pippin : gallery)};
`;

export const DateText = styled.div`
  color: ${darkGrey};
  font-size: 8px;
  transform: rotate(-90deg);
  min-width: 60px;
`;

export const DateTextAbsolute = styled.div`
  color: ${darkGrey};
  font-size: 8px;
  transform: rotate(-90deg);
  min-width: 62px;
  right: 0;
  position: absolute;
`;

export const DateTextNormal = styled.div`
  text-align: ${({ isLeft }) => (isLeft ? 'right' : 'left')};
  color: ${darkGrey};
  font-size: 8px;
  line-height: 8px;
`;

export const TitleText = styled.div`
  color: ${({ isPink }) => (isPink ? sunsetOrange : darkGrey)};
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
`;

export const StampRound = styled.div`
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  float: left;
`;

export const StampContent = styled.div`
  display: inline-block;
  width: calc(100% - 80px);
  margin-left: 10px;
  float: left;
  height: 70px;
`;
