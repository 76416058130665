import client from '../config/AxiosConfig';

export const GetWay = async id => client.get(`/ways/${id}`);

export const GetWays = async (currentPage, limit) =>
  client.get(`/ways/${currentPage}/${limit}`);

export const UpdateWay = async (id, data) => client.put(`/ways/${id}`, data);

export const DeleteWay = async id => client.delete(`/ways/${id}`);

export const GetWayCities = async (id, currentPage, limit) =>
  client.get(`/ways/${id}/cities/${currentPage}/${limit}`);
