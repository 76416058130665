import React, { Component } from 'react';
import moment from 'moment';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetPendingRequests } from '../../infra/requests/CertificateRequests';

class CertificateRequestsPage extends Component {
  state = {
    loading: false,
    columns: [
      {
        title: 'Requested From',
        dataIndex: 'user',
        render: value => value?.name
      },
      {
        title: 'Email',
        render: data => data?.user?.email
      },
      {
        title: 'Stamps',
        render: data => data?.user?.credential?.length || 0
      },
      {
        title: 'Requested At',
        dataIndex: 'createdAt',
        render: value => moment(value).format('HH:mm, DD/MM/YYYY')
      },
      {
        title: 'Last update',
        dataIndex: 'updatedAt',
        render: value => moment(value).format('HH:mm, DD/MM/YYYY')
      },
      {
        title: 'Manager',
        dataIndex: 'manager'
      },
      {
        title: 'Status',
        dataIndex: 'status'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetPendingRequests();
      this.setState({
        rows: result.data,
        total: result.data.length,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/users/${record.user._id}?requests=true`);
  };

  render() {
    const { columns, rows, total, loading } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Certificate Requests</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            alignContentTop
            currentPage={1}
            pageSize={total}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="audit"
            emptyText={'There are no pending certificate requests'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default CertificateRequestsPage;
