import React, { Component } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { SpinLoading } from "../../styles/BasicStyles";
import {
  UserCredentialSection,
  Title,
  NotFound,
  DatesLabel,
  DatesValue,
  CredentialIcon
} from "./ShareStyles";
import { GetUser } from "../../infra/requests/UsersRequests";
import UserCredential from "./components/UserCredential";
import EmptyCredential from "./components/EmptyCredential";
import FooterComponent from "./components/FooterComponent";
import ShareIcon from "../../assets/icons/share_icon.svg";

const REACT_WEB_URL = process.env.REACT_WEB_URL;

class ShareCredentialPage extends Component {
  state = {
    loading: false,
    ready: false,
    error: false,
    user: {},
    credential: [],
    total: 0
  };

  componentDidMount = () => {
    this.updateDataTable();
  };

  updateDataTable = async () => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    try {
      const { data, success } = await GetUser(params.id);
      if (success) {
        this.setState({
          user: data,
          credential: data?.credential,
          total: data?.credential?.length,
          loading: false,
          ready: true
        });
      } else
        this.setState({
          error: true,
          loading: false,
          ready: true
        });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  renderDates = () => {
    const { user } = this.state;
    if (user?.credential) {
      const start = user.credential[user.credential.length - 1];
      const end = user.credential[0];
      return `${moment(start.createdAt).format("DD | MM | YYYY")} - ${moment(
        end.createdAt
      ).format("DD | MM | YYYY")}`;
    }
    return "";
  };

  renderContent = () => {
    const { user, ready, error } = this.state;
    if (!ready) return <SpinLoading />;
    if (error)
      return (
        <NotFound>The page you are trying to share does not exist!</NotFound>
      );
    return (
      <React.Fragment>
        <Title subtitle>{user?.name}</Title>
        <DatesLabel>Start and end date</DatesLabel>
        <DatesValue>{this.renderDates()}</DatesValue>
        {user?.credential?.length > 0 ? (
          <UserCredential credential={user.credential} />
        ) : (
          <EmptyCredential />
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <UserCredentialSection>
        <Helmet>
          <title>My Credential</title>
          <link rel="canonical" href={REACT_WEB_URL} />
          <meta
            name="description"
            content="I would like to share my digital credential of my progress inside the Saint James Way project"
          />
        </Helmet>
        <Title>Saint James Way Credential</Title>
        <CredentialIcon src={ShareIcon} />
        {this.renderContent()}
        <FooterComponent />
      </UserCredentialSection>
    );
  }
}

export default ShareCredentialPage;
