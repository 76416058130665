import React, { Component, Fragment } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
  TableImage
} from '../../styles/BasicStyles';
import {
  GetCommentsToApprove,
  ApproveComment,
  DeleteComment
} from '../../infra/requests/CommentsRequests';
import ImageComponent from '../../components/images/ImageComponent';

const deleteMessage = (
  <Fragment>
    <div>Do you want to remove this comment?</div>
    <div>The user will be notified and the comment cannot be recovered</div>
  </Fragment>
);

class ApproveCommentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [
      {
        title: 'Point of Interest',
        dataIndex: 'poi',
        render: value => value?.name
      },
      {
        title: 'City',
        render: data => data?.poi?.city?.name
      },
      {
        title: 'User',
        dataIndex: 'user',
        render: value => value?.name
      },
      {
        title: 'Message',
        dataIndex: 'message'
      },
      {
        title: 'Image',
        dataIndex: 'image',
        render: value =>
          value && (
            <TableImage>
              <ImageComponent color={value.color} url={value.url} />
            </TableImage>
          )
      },
      {
        title: 'Actions',
        render: data => (
          <React.Fragment>
            {!data.validated && (
              <Popconfirm
                placement="topRight"
                title="Do you want to approve this comment?"
                onConfirm={() => this.approveComment(data)}
              >
                <Icon type="like" />
                {' Approve'}
              </Popconfirm>
            )}
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={deleteMessage}
                onConfirm={() => this.deleteItem(data)}
              >
                <Icon type="delete" />
                {' Remove'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const {
        match: { params }
      } = this.props;
      const { currentPage, pageSize } = this.state;
      const result = await GetCommentsToApprove(
        params.id,
        currentPage,
        pageSize
      );
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleOnCancel = () => {
    const {
      history,
      match: { params }
    } = this.props;
    history.push(`/ways/${params.way}`);
  };

  approveComment = async comment => {
    await ApproveComment(comment._id);
    await this.updateDataTable();
  };

  deleteItem = async item => {
    await DeleteComment(item._id);
    await this.updateDataTable();
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Approve Comments</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            alignContentTop
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="message"
            emptyText={'There are no comments to approve'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ApproveCommentsPage;
