import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetAdmin,
  UpdateAdmin,
  CreateAdmin
} from '../../infra/requests/AdminsRequests';
import TextInput from '../../components/inputs/TextInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  name: 'required',
  email: 'required'
});

class ManageAdminsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    companies: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if (params.id) {
      const result = await GetAdmin(params.id);
      dispatch(initialize('manage_admin_form', result.data));
      this.setState({loading: false});
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;
      const { success } = isNew
        ? await CreateAdmin(values)
        : await UpdateAdmin(params.id, values);
      if (success) return history.push('/admins');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/admins');
  };

  render() {
    const { handleSubmit } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Add' : 'Edit';
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
            {`Admins > ${title}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
          <BaseButton
                type="primary"
                icon="save"
                text="Save"
                onClick={handleSubmit(this.onSubmit)}
              />
          <BaseButton
                type="default"
                icon="close"
                text="Cancel"
                onClick={this.handleOnCancel}
              />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Name"
              placeholder="First and last name"
            />
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="User email"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageAdminsPage = reduxForm({
  form: 'manage_admin_form',
  validate: validations
})(ManageAdminsPage);


const mapStateToProps = state => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageAdminsPage));
