import React from "react";
import StampsComponent from "./stamps/StampsComponent";
import StackGrid from "react-stack-grid";

const UserCredential = ({ credential = [] }) => {
  return (
    <StackGrid
      columnWidth={180}
      gutterWidth={5}
      gutterHeight={5}
      style={{ marginTop: "60px" }}
    >
      {credential.map(stamp => (
        <StampsComponent key={stamp._id} stamp={stamp} />
      ))}
    </StackGrid>
  );
};

export default UserCredential;
