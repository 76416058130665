import React from 'react';
import {
  StampRectangleContainer,
  RectangleStampContainer,
  Stamp,
  RectangleTextContainer,
  TitleText,
  RectangleTextDateContainer,
  IconPinkSquareImage,
  DateTextNormal,
  RectangleTextBottom,
  StampRound
} from './DefaultStampStyles';
import Pattern4 from '../../../../assets/images/pattern4.png';

const RT2 = ({ tdate, text, image }) => (
  <StampRectangleContainer>
    <RectangleStampContainer>
      <RectangleTextContainer>
        <RectangleTextDateContainer>
          <DateTextNormal>{tdate}</DateTextNormal>
        </RectangleTextDateContainer>
        <IconPinkSquareImage src={Pattern4} />
        <RectangleTextBottom>
          <TitleText numberOfLines={2}>{text}</TitleText>
        </RectangleTextBottom>
      </RectangleTextContainer>
      <StampRound>
        <Stamp sizew="70" sizeh="70" src={image} />
      </StampRound>
    </RectangleStampContainer>
  </StampRectangleContainer>
);

export default RT2;
