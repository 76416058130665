import { GetInfo } from '../../infra/requests/BaseRequests';
import { SaveInfo } from '../Info/info.actions';
import { initialize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { SaveUser } from '../User/user.actions';

export default function start() {
  return async dispatch => {
    const info = await GetInfo();
    dispatch(SaveUser(info?.data?.user));
    dispatch(SaveInfo(info?.data));
    dispatch(
      initialize({
        languages: info?.data?.languages,
        translation: [],
        options: {
          renderToStaticMarkup,
          defaultLanguage: info?.data?.default_language
        }
      })
    );
  };
}
