import React, { Component } from 'react';
import Table from '../../components/table/Table';
import BaseButton from '../../components/buttons/BaseButton';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle,
  SpinLoading
} from '../../styles/BasicStyles';
import { GetWayCities } from '../../infra/requests/WayRequests';
import CitiesMap from '../../components/maps/CitiesMap';

class ManageCityPage extends Component {
  state = {
    pageSize: 80,
    currentPage: 1,
    loading: false,
    ready: false,
    way: {},
    columns: [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Latitude',
        dataIndex: 'coordinates.latitude'
      },
      {
        title: 'Longitude',
        dataIndex: 'coordinates.longitude'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const {
      history,
      match: { params }
    } = this.props;
    history.push(`/ways/${params.way}/cities/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;
      const {
        match: { params }
      } = this.props;
      const result = await GetWayCities(params.way, currentPage, pageSize);
      this.setState({
        way: result.data.way,
        rows: result.data.items,
        total: result.data.total,
        ready: true,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      way,
      ready
    } = this.state;
    const {
      history,
      match: { params }
    } = this.props;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>{way.name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'default'}
              icon={'arrow-left'}
              text="Back to Way"
              onClick={() => history.push(`/ways/${params.way}`)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <SectionTitle>Map</SectionTitle>
          <CitiesMap way={way} cities={rows} />
          <SectionTitle subsection>Cities</SectionTitle>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="shop"
            emptyText={'There are no cities'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ManageCityPage;
