import React, { Component, Fragment } from 'react';
import { Icon, Popconfirm } from 'antd';
import Table from '../../../components/table/Table';
import { TableButton } from '../../../styles/BasicStyles';
import moment from 'moment';
import { CertificateStatus } from '../../../infra/requests/CertificateRequests';

class UserRequests extends Component {
  state = {
    loading: false,
    user: {},
    columns: [
      {
        title: 'Requested At',
        dataIndex: 'createdAt',
        render: value => moment(value).format('HH:mm, DD/MM/YYYY')
      },
      {
        title: 'Last update',
        dataIndex: 'updatedAt',
        render: value => moment(value).format('HH:mm, DD/MM/YYYY')
      },
      {
        title: 'Manager',
        dataIndex: 'manager'
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Options',
        render: data => (
          <Fragment>
            {data.status === 'Requested' && (
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title="If you change the status to Pending, the user will receive an email saying that the process is being validated."
                  onConfirm={() => this.changeStatus(data, 'Pending')}
                >
                  <Icon type="swap" />
                  {' Change to Pending'}
                </Popconfirm>
              </TableButton>
            )}
            {data.status === 'Pending' && (
              <Fragment>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title="If you change the status to Approved, the user will receive an email saying that the credential is valid and that he will receive it soon by email."
                    onConfirm={() => this.changeStatus(data, 'Approved')}
                  >
                    <Icon type="check-circle" />
                    {' Approved'}
                  </Popconfirm>
                </TableButton>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title="If you change the status to Rejected, the user will receive an email saying that the credential does not meet the necessary requirements."
                    onConfirm={() => this.changeStatus(data, 'Rejected')}
                  >
                    <Icon type="stop" />
                    {' Rejected'}
                  </Popconfirm>
                </TableButton>
              </Fragment>
            )}
          </Fragment>
        )
      }
    ]
  };

  changeStatus = async (record, status) => {
    await CertificateStatus(record._id, { status });
    const { handleChange } = this.props;
    handleChange();
  };

  handleOnPressRow = record => {};

  render() {
    const { certificates = [], loading } = this.props;
    const { columns } = this.state;
    return (
      <Table
        columns={columns}
        currentPage={1}
        pageSize={certificates.length}
        loading={loading}
        rows={certificates}
        showHeader={true}
        emptyIcon="audit"
        emptyText={'The user did not request any certificates yet'}
        total={certificates.length}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.handleOnPressRow}
      />
    );
  }
}

export default UserRequests;
