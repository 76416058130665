import React from 'react';
import {
  StampRectangleContainer,
  RectangleStampContainer,
  Stamp,
  RectangleTextContainer,
  TitleText,
  IconPinkSquareImage,
  DateTextNormal,
  RectangleTextBottom,
  StampRound
} from './DefaultStampStyles';
import Pattern3 from '../../../../assets/images/pattern3.png';

const RT3 = ({ tdate, text, image }) => (
  <StampRectangleContainer>
    <RectangleStampContainer>
      <RectangleTextContainer>
        <RectangleTextBottom Normal>
          <TitleText numberOfLines={2}>{text}</TitleText>
        </RectangleTextBottom>
        <IconPinkSquareImage src={Pattern3} />
        <DateTextNormal>{tdate}</DateTextNormal>
      </RectangleTextContainer>
      <StampRound>
        <Stamp sizew="70" sizeh="70" src={image} />
      </StampRound>
    </RectangleStampContainer>
  </StampRectangleContainer>
);

export default RT3;
