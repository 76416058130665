import React from 'react';
import { Row, Col } from 'antd';
import { EmptyStamp } from '../ShareStyles';

const EmptyCredential = () => {
  return (
    <Row gutter={12} type="flex" justify="center" align="top">
      <Col xs={24}>
        <EmptyStamp />
        <EmptyStamp half />
        <EmptyStamp half />
        <EmptyStamp />
        <EmptyStamp />
        <EmptyStamp half />
        <EmptyStamp half />
        <EmptyStamp />
        <EmptyStamp half />
        <EmptyStamp half />
      </Col>
    </Row>
  );
};

export default EmptyCredential;
