import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import BaseButton from '../../components/buttons/BaseButton';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle,
  TableButton,
  SpinLoading
} from '../../styles/BasicStyles';
import { GetWayPois, DeletePoi } from '../../infra/requests/PoiRequests';
import PoiFilters from './components/PoiFilters';
import PoisMap from '../../components/maps/PoisMap';

const REACT_APP_API = process.env.REACT_APP_API;

class WaysPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    ready: false,
    way: {},
    cities: [],
    filters: '',
    columns: [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        render: value => value?.name
      },
      {
        title: 'City',
        dataIndex: 'city',
        render: value => value?.name
      },
      {
        title: 'Actions',
        render: data => (
          <React.Fragment>
            <TableButton onClick={e => this.openComments(e, data)}>
              <Icon type="message" />
              {' See All Comments'}
            </TableButton>
            <TableButton onClick={e => this.getQrCode(e, data)}>
              <Icon type="qrcode" />
              {' Get Qrcode'}
            </TableButton>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Do you want to delete this admin? You will not be able to recover it."
                onConfirm={() => this.deleteItem(data)}
              >
                <Icon type="delete" />
                {' Delete'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    this.updateDataTable();
  };

  handleOnPressRow = record => {
    const {
      history,
      match: { params }
    } = this.props;
    history.push(`/ways/${params.way}/poi/${record._id}`);
  };

  handleFilterChange = filters => {
    this.setState({ filters }, this.updateDataTable);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const {
        match: { params }
      } = this.props;
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetWayPois(
        params.way,
        currentPage,
        pageSize,
        filters
      );
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        way: result.data.way,
        cities: result.data.cities,
        loading: false,
        ready: true
      });
    } catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  };

  deleteItem = async item => {
    await DeletePoi(item._id);
    await this.updateDataTable();
  };

  getQrCode = async (event, item) => {
    event.stopPropagation();
    window.open(`${REACT_APP_API}/pois/${item._id}/qrcode`);
  };

  openComments = async (event, item) => {
    const {
      history,
      match: { params }
    } = this.props;
    event.stopPropagation();
    history.push(`/ways/${params.way}/poi/${item._id}/comments`);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      ready,
      currentPage,
      pageSize,
      way,
      cities
    } = this.state;
    const {
      history,
      match: { params }
    } = this.props;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>{way.name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type="danger"
              icon="plus"
              text="Add Point of Interest"
              onClick={() => history.push(`/ways/${params.way}/poi/add`)}
            />
            <BaseButton
              type="primary"
              icon="shop"
              text="Cities"
              onClick={() => history.push(`/ways/${params.way}/cities`)}
            />
            <BaseButton
              type="primary"
              icon="edit"
              text="Edit Way"
              onClick={() => history.push(`/ways/${params.way}/edit`)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <SectionTitle>Map</SectionTitle>
          <PoisMap way={way} pois={rows} />
          <SectionTitle subsection>Points of Interest</SectionTitle>
          <PoiFilters cities={cities} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="home"
            emptyText={'There are no Points of Interest'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default WaysPage;
