import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle,
  SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetUser } from '../../infra/requests/UsersRequests';
import moment from 'moment';
import UserProfile from './components/UserProfile';
import UserRequests from './components/UserRequests';
import UserStampsMap from '../../components//maps/UserStampsMap';
import queryString from 'query-string';

const REACT_APP_API = process.env.REACT_APP_API;

class UserCredentialPage extends Component {
  state = {
    loading: false,
    ready: false,
    user: {},
    certificates: [],
    columns: [
      {
        title: 'Way',
        dataIndex: 'poi',
        render: value => value?.city?.way?.name
      },
      {
        title: 'City',
        render: data => data?.poi?.city?.name
      },
      {
        title: 'Point of Interest',
        dataIndex: 'name'
      },
      {
        title: 'Stamp at',
        dataIndex: 'createdAt',
        render: value => moment(value).format('HH:mm, DD/MM/YYYY')
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {};

  updateDataTable = async () => {
    const {
      match: { params }
    } = this.props;
    this.setState({ loading: true });
    try {
      const result = await GetUser(params.id);
      this.setState({
        user: result.data,
        certificates: result.data?.certificates,
        rows: result.data?.credential,
        total: result.data?.credential?.length,
        loading: false,
        ready: true
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleOnCancel = () => {
    const {
      history,
      location: { search }
    } = this.props;
    const params = queryString.parse(search);
    if (params.requests) return history.push('/requests');
    return history.push('/users');
  };

  downloadCsv = () => {
    const {
      match: { params }
    } = this.props;
    window.open(`${REACT_APP_API}/users/${params.id}/csv`);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      user,
      certificates,
      ready
    } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{user.name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="download"
              text="Download Stamp List"
              onClick={this.downloadCsv}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancel"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UserProfile user={user} />
          <SectionTitle subsection>Certificates</SectionTitle>
          <UserRequests
            certificates={certificates}
            loading={loading}
            handleChange={this.updateDataTable}
          />
          <SectionTitle subsection>Stamps on map</SectionTitle>
          <UserStampsMap stamps={rows} />
          <SectionTitle subsection>List of Stamps</SectionTitle>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={total}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="audit"
            emptyText={'The user has no stamps'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default UserCredentialPage;
