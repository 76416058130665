import EN from './uk_flag.svg';
import FR from './france_flag.svg';
import PT from './portugal_flag.svg';
import ES from './spain_flag.svg';
import LT from './lithuanian_flag.svg';
import PL from './poland_flag.svg';
import BE from './belgium_flag.svg';

export const GetAvailableLanguages = () => ['en', 'pt', 'es', 'fr', 'lt', 'pl', 'be'];

export const Languages = [
  { code: 'en', name: 'English', icon: EN },
  { code: 'pt', name: 'Portuguese', icon: PT },
  { code: 'es', name: 'Spanish', icon: ES },
  { code: 'fr', name: 'French', icon: FR },
  { code: 'lt', name: 'Lithuanian', icon: LT },
  { code: 'pl', name: 'Polish', icon: PL },
  { code: 'be', name: 'Belgium', icon: BE }
];
