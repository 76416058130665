import styled, { createGlobalStyle } from 'styled-components';
import { Spin, Form as AntForm } from 'antd';
import { StyledButton } from '../components/buttons/ButtonsStyles';

export const BasicStyles = createGlobalStyle`

  @import 'cropperjs/dist/cropper.css';

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

`;

export const HeaderContainer = styled.div`
  width: calc(100% - 80px);
  display: flex;
  position: fixed;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  z-index: 50;
  border-bottom: 2px solid ${({ theme }) => theme.secondaryBackgroundColor};
  align-items: center;
`;

export const HeaderTitle = styled.div`
  width: calc(
    100% - ${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')}
  );
  display: inline-block;
  text-align: left;
`;

export const HeaderButtonsContainer = styled.div`
  width: calc(${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')});
  float: right;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }
`;

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 5px;
`;

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.sectionSize};
  color: ${({ theme }) => theme.thirdColor};
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({ subsection }) => (subsection ? '50px' : 0)};
`;

export const PageContainer = styled.div`
  width: 100%;
  margin-top: 105px;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const TableButton = styled.div`
  display: inline-block;
  z-index: 999;
  padding: 5px;
  margin: 0 10px;

  &:hover {
    color: ${({ theme, primary, error }) =>
      primary ? theme.primaryColor : error ? theme.inputErrorColor : 'inherit'};
  }
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? '600px' : '100%')};
  margin-top: 105px;
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${p => p.theme.primaryBackgroundColor};
  text-align: left;
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 20px 0;
`;

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`;

export const Separator = styled.hr`
  margin: 20px 0px;
`;
