import client from '../config/AxiosConfig';

export const GetInfo = async () => client.get('/info');

export const GetDynamicPages = async () => client.get('/dynamic-pages');

export const GetDynamicPage = async tag => client.get(`/dynamic-pages/${tag}`);

export const UpdateDynamicPage = async (tag, data) =>
  client.put(`/dynamic-pages/${tag}`, data);

export const CitiesDropdown = async way =>
  client.get(`/cities/dropdown/${way}`);
