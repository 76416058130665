import LoginPage from '../../pages/auth/LoginPage';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';
import RecoverAccountPage from '../../pages/auth/RecoverAccountPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import ManageDynamicPages from '../../pages/dynamicPages/ManageDynamicPages';
import ShareCredentialPage from '../../pages/share/ShareCredentialPage';
import ShareAppPage from '../../pages/share/ShareAppPage';
import EmailTemplatesPage from '../../pages/email_templates/EmailTemplatesPage';
import ManageEmailTemplatesPage from '../../pages/email_templates/ManageEmailTemplatesPage';

export default [
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true
  },
  {
    path: '/dynamic-pages/:tag',
    component: ManageDynamicPages,
    isAuthenticated: true
  },
  {
    path: '/login',
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: '/recover-account',
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: '/recover-password',
    component: ResetPasswordPage,
    isAuthenticated: false
  },
  {
    path: '/share/app/:type/:id',
    component: ShareAppPage,
    isAuthenticated: false
  },
  {
    path: '/share/:id',
    component: ShareCredentialPage,
    isAuthenticated: false
  },
  {
    path: '/email-templates',
    component: EmailTemplatesPage,
    isAuthenticated: true
  },
  {
    path: '/email-templates/:id',
    component: ManageEmailTemplatesPage,
    isAuthenticated: true
  }
];
