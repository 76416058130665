import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import {
  Field,
  reduxForm,
  initialize,
  FieldArray,
  FormSection
} from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  GetCity,
  UpdateCity,
  AddCityImage,
  RemoveCityImage
} from "../../infra/requests/CityRequests";
import TextInput from "../../components/inputs/TextInput";
import WebsiteInput from "../../components/inputs/WebsiteInput";
import NumberInput from "../../components/inputs/NumberInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import PrefixNumber from "../../components/inputs/PrefixNumber";
import LanguagesInput from "../../components/inputs/LanguagesInput";
import ImageSlideInput from "../../components/inputs/ImageSlideInput";
import MapInput from "../../components/inputs/MapInput";
import BaseButton from "../../components/buttons/BaseButton";
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";

const validations = FormValidator.make({
  name: "required",
  description: "required|emptyLanguage",
  coordinates: "required"
});

class ManageCityPage extends Component {
  state = {
    isNew: false,
    loading: true,
    companies: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    const result = await GetCity(params.id);
    dispatch(initialize("manage_city_form", result.data));
    this.setState({ loading: false });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { success } = await UpdateCity(params.id, values);
      if (success) return history.push(`/ways/${params.way}/cities`);
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;
    return history.push(`/ways/${params.way}/cities`);
  };

  handleDeleteImage = async id => {
    const {
      match: { params }
    } = this.props;
    return await RemoveCityImage(params.id, id);
  };

  handleAddImage = async image => {
    const {
      match: { params }
    } = this.props;
    const { data, success } = await AddCityImage(params.id, image);
    if (success) return data;
    return undefined;
  };

  render() {
    const { handleSubmit } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? "Add" : "Edit City";
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Save"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancel"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <SectionTitle>Basic Info</SectionTitle>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Name"
              placeholder="Name the City"
            />
            <Field
              component={LanguagesInput}
              name="description"
              label="About the City"
              placeholder="Description of the city"
            />
            <Field
              component={TextAreaInput}
              name="schedule"
              type="text"
              label="Schedule"
              placeholder="Schedule in City"
            />
            <Field
              component={TextInput}
              name="address"
              type="text"
              label="Address"
              placeholder="Address"
            />
            <FormSection name="contact">
              <Row gutter={12}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name="name"
                    type="text"
                    label="Contact Name"
                    placeholder="Contact Name"
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    component={PrefixNumber}
                    name="prefix"
                    label="Prefix"
                    placeholder="Prefix"
                  />
                </Col>
                <Col xs={18}>
                  <Field
                    component={NumberInput}
                    name="number"
                    type="number"
                    step={1}
                    label="Number"
                    placeholder="Number"
                  />
                </Col>
              </Row>
            </FormSection>
            <Field
              component={TextInput}
              name="email"
              type="text"
              label="Email"
              placeholder="Email"
            />
            <Field
              component={WebsiteInput}
              name="website"
              type="text"
              label="Website"
              placeholder="Website"
            />
            <SectionTitle subsection>Gallery</SectionTitle>
            <FieldArray
              component={ImageSlideInput}
              name="images"
              label="Images"
              handleAdd={this.handleAddImage}
              handleDelete={this.handleDeleteImage}
            />
            <SectionTitle subsection>Position</SectionTitle>
            <Field
              component={MapInput}
              name="coordinates"
              label="Coordinates"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCityPage = reduxForm({
  form: "manage_city_form",
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageCityPage);

const mapStateToProps = state => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageCityPage));
